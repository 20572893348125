import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import { IConfig } from '../interfaces/config.interface';
import { ICompanyAccessList } from '../interfaces/users.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly STORAGE_KEY = 'companyAccessList';
  private readonly STORAGE_KEY_FULL_NAME = 'userFullName';
  private stateItem: BehaviorSubject<ICompanyAccessList | null>;
  private fullNameSubject: BehaviorSubject<string | null>;

  config: IConfig;

  constructor(private httpClient: HttpClient) {
    this.config = {
      apiBaseUrl: env.apiBaseUrl,
      contentType: env.contentType
    };
    const storedItem = localStorage.getItem(this.STORAGE_KEY);
    this.stateItem = new BehaviorSubject<ICompanyAccessList | null>(storedItem ? JSON.parse(storedItem) : null);

    const storedName = localStorage.getItem(this.STORAGE_KEY_FULL_NAME);
    this.fullNameSubject = new BehaviorSubject<string | null>(storedName ? storedName : null);
  }

  public getCompanyAccess(): Observable<ICompanyAccessList> {
    console.log('🚀 ~ UserService ~ getCompanyAccess');
    return this.httpClient.get(`${this.config.apiBaseUrl}/user/company-access-list/`).pipe(
      switchMap((response: any) => {
        console.log('🚀 ~ UserService ~ map ~ response:', response);
        const companyAccessList = response as ICompanyAccessList;

        // Chain the active company session call and map it back to companyAccessList
        return this.getActiveCompanySession().pipe(
          map((activeSession: any) => {
            console.log('🚀 ~ UserService ~ Active Company Session:', activeSession);

            // Update the active company in the companyAccessList
            const activeCompanyId = activeSession?.company_id;
            if (activeCompanyId) {
              const activeCompany = companyAccessList.companies.find(company => company.company_id === activeCompanyId);
              if (activeCompany) {
                companyAccessList.activeCompany = activeCompany;
                console.log('🚀 ~ UserService ~ Updated activeCompany:', activeCompany);
              }
            } else {
              console.log('🚀 ~ UserService ~ No active Company');
              companyAccessList.activeCompany = companyAccessList.companies[0];
              console.log('🚀 ~ UserService ~ Updated activeCompany:', companyAccessList.companies[0]);
            }

            // Set state with the updated companyAccessList
            this.setState(companyAccessList);

            return companyAccessList; // Return the updated companyAccessList with activeCompany set
          })
        );
      }),
      catchError(this.handleError)
    );
  }

  public getActiveCompanySession(): Observable<any> {
    console.log('🚀 ~ UserService ~ getActiveCompanySession');
    return this.httpClient.get(`${this.config.apiBaseUrl}/account/session-information/update/`).pipe(
      map((response: any) => {
        console.log('🚀 ~ UserService ~ getActiveCompanySession ~ map ~ response:', response);
        return response;
      }),
      catchError(this.handleError)
    );
  }

  public updateEvidenceSubmission(id: number, comment: string): Observable<any> {
    return this.httpClient
      .patch(`${this.config.apiBaseUrl}/controls/evidence/review/comment/${id}/`, {
        comment: comment
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public setActiveCompanySession(company_id: number): Observable<any> {
    console.log('🚀 ~ UserService  ~ setActiveCompanySession:', company_id);
    return this.httpClient
      .patch(`${this.config.apiBaseUrl}/account/session-information/update/`, {
        company_id: company_id
      })
      .pipe(
        switchMap((response: any) => {
          console.log('🚀 ~ UserService ~ map ~ response:', response);
          const companyAccessList: ICompanyAccessList | null = this.stateItem.getValue();

          if (companyAccessList) {
            // Chain the active company session call and map it back to companyAccessList
            return this.getActiveCompanySession().pipe(
              map((activeSession: any) => {
                console.log('🚀 ~ UserService ~ Active Company Session:', activeSession);

                // Update the active company in the companyAccessList
                const activeCompanyId = activeSession?.company_id;
                if (activeCompanyId) {
                  const activeCompany = companyAccessList.companies.find(
                    company => company.company_id === activeCompanyId
                  );
                  if (activeCompany) {
                    companyAccessList.activeCompany = activeCompany;
                    console.log('🚀 ~ UserService ~ Updated activeCompany:', activeCompany);
                  }
                }

                // Set state with the updated companyAccessList
                this.setState(companyAccessList);

                return companyAccessList; // Return the updated companyAccessList with activeCompany set
              })
            );
          }
          // return response;
          return throwError(() => new Error('Unable to find company in user company access list.'));
        }),
        catchError(this.handleError)
      );
  }

  public updateUserInfo(userInfo: IUserInfoUpdate): Observable<any> {
    console.log('🚀 ~ UserService ~ updateUserInfo ~ userInfo:', userInfo);
    return this.httpClient.patch(`${this.config.apiBaseUrl}/account/update/`, userInfo).pipe(
      map((response: any) => {
        console.log('🚀 ~ UserService ~ map ~ response:', response);

        // Get the current state synchronously
        const companyAccessList: ICompanyAccessList | null = this.stateItem.getValue();

        if (companyAccessList) {
          const companyIndex = companyAccessList.companies.findIndex(c => c.company_id);
          if (companyIndex !== -1) {
            const updatedCompany = {
              ...companyAccessList.companies[companyIndex],
              company_roles: userInfo.company_roles,
              departments: userInfo.departments
            };

            const updatedCompanyAccessList = {
              ...companyAccessList,
              activeCompany: updatedCompany,
              companies: [
                ...companyAccessList.companies.slice(0, companyIndex),
                updatedCompany,
                ...companyAccessList.companies.slice(companyIndex + 1)
              ]
            };

            // Set the updated state
            this.setState(updatedCompanyAccessList);
          }
        }

        return response;
      }),
      catchError(this.handleError)
    );
  }

  public deleteUser(): Observable<any> {
    return this.httpClient.delete(`${this.config.apiBaseUrl}/account/delete/`, { observe: 'response' }).pipe(
      tap((response: any) => {
        if (response.status === 204) {
          console.log('Your account has been deleted!');
        }
      }),
      catchError((error: any) => {
        let errMsg = '';

        if (error instanceof HttpErrorResponse) {
          // Handle HttpErrorResponse specifically
          if (error.status === 401) {
            errMsg = 'Unauthorized: Access is denied due to invalid credentials.';
          } else if (error.status === 403) {
            errMsg =
              error.error?.error ||
              'Account is the owner of a company, must transfer ownership before deleting their account.';
          } else {
            errMsg = 'An unexpected error occurred: ' + error.message;
          }
        } else if (error.error) {
          // Handle custom error object from django
          errMsg = 'An error occurred: ' + error.error;
        } else {
          // Handle other types of errors
          errMsg = 'An unknown error occurred: ' + error.message;
        }

        return throwError(() => new Error(errMsg));
      })
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.log('🚀 ~ UserService ~ handleError ~ error:', error);
    return throwError(() => 'Something went wrong; please try again later.');
  }

  getRole(): Observable<string | null> {
    return this.stateItem.pipe(
      map(companyAccessList => {
        if (companyAccessList && companyAccessList.companies.length > 0) {
          const firstCompany = companyAccessList.activeCompany;
          return firstCompany.role;
        }
        return null;
      })
    );
  }

  getCompanyType(): Observable<string | null> {
    return this.stateItem.pipe(
      map(companyAccessList => {
        if (companyAccessList && companyAccessList.companies.length > 0) {
          const firstCompany = companyAccessList.activeCompany;
          return firstCompany.company_type;
        }
        return null;
      })
    );
  }

  getActiveCompanyId(): Observable<number | null> {
    return this.stateItem.pipe(
      map(companyAccessList => {
        if (companyAccessList && companyAccessList.companies.length > 0) {
          const firstCompany = companyAccessList.activeCompany;
          return firstCompany.company_id;
        }
        return null;
      })
    );
  }

  hasRole(roles: string[]): Observable<boolean> {
    return this.getRole().pipe(map(role => roles.includes(role || '')));
  }

  get fullName$(): Observable<string | null> {
    return this.fullNameSubject.asObservable();
  }

  updateFullName(newFullName: string): void {
    this.fullNameSubject.next(newFullName);
    localStorage.setItem(this.STORAGE_KEY_FULL_NAME, newFullName);
  }

  getEmail(): Observable<string | null> {
    return this.stateItem.pipe(map(companyAccessList => (companyAccessList ? companyAccessList.email : null)));
  }

  getId(): Observable<number | null> {
    return this.stateItem.pipe(map(companyAccessList => (companyAccessList ? companyAccessList.id : null)));
  }

  setState(item: ICompanyAccessList): void {
    console.log('🚀 ~ UserService ~ setState ~ item:', item);
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(item));
    this.stateItem.next(item);
  }

  getState(): Observable<ICompanyAccessList | null> {
    console.log('🚀 ~ UserService ~ getState');
    return this.stateItem.asObservable();
  }

  removeState() {
    console.log('🚀 ~ UserService ~ removeState');
    localStorage.removeItem(this.STORAGE_KEY);
    localStorage.removeItem('hasSeenOrgModal');
    localStorage.removeItem('userFullName');
    this.stateItem.next(null);
  }
}

export interface IUserInfoUpdate {
  company_id: number;
  full_name: string;
  departments: string[];
  company_roles: string[];
}

export interface IUserInfoUpdateResponse {
  id: number;
  full_name: string;
  departments: string[];
  company_roles: string[];
}
